// Libraries
import React from 'react';

// Supermove
import {Modal, Icon, Styled, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Wrapper = Styled.View<{
  isResponsive: boolean;
  mobile?: boolean;
}>`
  ${({isResponsive, mobile}) => (isResponsive && mobile ? 'width: 100%;' : 'width: 400px;')}
  ${({isResponsive, mobile}) => (isResponsive && mobile ? 'flex: 1;' : '')}
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
`;

const Header = Styled.View`
  padding-horizontal: 20px;
`;

const TitleRow = Styled.View`
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  flex: 1;
  font-weight: 700;
`;

const IconView = Styled.View`
`;

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Touchable = Styled.Touchable`
  justify-content: center;
`;

const CloseButton = ({onPress}: {onPress: () => void}) => {
  return (
    <Touchable onPress={onPress}>
      <Icon color={colors.gray.primary} size={18} source={Icon.Times} />
    </Touchable>
  );
};

const SmallModalV1 = ({
  isResponsive = true,
  isOpen,
  icon,
  title = null,
  subtitle,
  onClose,
  children,
}: {
  isResponsive: boolean;
  isOpen: boolean;
  icon?: React.ReactNode;
  title?: string | null;
  subtitle?: string;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper {...responsive} isResponsive={isResponsive}>
          <Header>
            <TitleRow>
              <IconView>{icon}</IconView>
              {!!icon && !!title && <Space width={8} />}
              <Title>{title}</Title>
              <CloseButton onPress={onClose} />
            </TitleRow>
            <Subtitle>{subtitle}</Subtitle>
          </Header>
          {children}
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

export default SmallModalV1;
