// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled, Icon, IconSource} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Modal from '@shared/design/components/Modal';
import Sheet from '@shared/design/components/Sheet';

const Column = Styled.View`
`;

const Container = Styled.View`
`;

const MobilePrimaryActionContainer = Styled.View`
  padding: 16px;
`;

const MediumModal = ({
  title,
  description,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isSubmitting,
  isDisabled,
  isScrollable,
  style,
  HeaderComponent,
  children,
  primaryActionColor,
  primaryActionIcon = Icon.Check,
  isMobileSheet,
  mobileContainerStyle,
}: {
  title: string;
  description?: string;
  isOpen: boolean;
  handlePressOutside?: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction?: () => void;
  primaryActionText: string;
  secondaryActionText?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  isScrollable?: boolean;
  style?: object;
  HeaderComponent?: React.FC | null;
  primaryActionColor?: string;
  primaryActionIcon?: IconSource;
  children: React.ReactNode;
  isMobileSheet?: boolean;
  mobileContainerStyle?: object | null;
}) => {
  const responsive = useResponsive();

  if (isMobileSheet && !responsive.desktop) {
    return (
      <Sheet
        isOpen={isOpen}
        handleClose={handleSecondaryAction}
        headerText={title}
        height={'90%'}
        shouldCloseOnClickOutside
      >
        <Container style={{flex: 1, padding: 16, ...mobileContainerStyle}}>{children}</Container>
        <MobilePrimaryActionContainer>
          {!!handlePrimaryAction && (
            <Button
              text={primaryActionText}
              onPress={() => setTimeout(handlePrimaryAction, 0)}
              isWidthOfContainer
              isSubmitting={isSubmitting}
              isDisabled={isDisabled}
              iconLeft={primaryActionIcon}
              isResponsive
            />
          )}
        </MobilePrimaryActionContainer>
      </Sheet>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      width={Modal.WIDTH.MEDIUM}
      style={style}
    >
      {(HeaderComponent && <HeaderComponent />) || (
        <Modal.Header>
          <Column>
            <Modal.HeaderText responsive={responsive}>{title}</Modal.HeaderText>
            {description && (
              <React.Fragment>
                <Space height={8} />
                <Modal.HeaderDescription responsive={responsive}>
                  {description}
                </Modal.HeaderDescription>
              </React.Fragment>
            )}
          </Column>
        </Modal.Header>
      )}
      <Modal.Body isScrollable={isScrollable}>{children}</Modal.Body>
      <Modal.Footer>
        <Modal.Button isDisabled={isDisabled} onPress={handleSecondaryAction} isResponsive>
          {secondaryActionText}
        </Modal.Button>
        <Space width={16} />
        <Modal.Button
          isSubmitting={isSubmitting}
          isDisabled={isDisabled}
          onPress={handlePrimaryAction}
          color={primaryActionColor}
          iconLeft={primaryActionIcon}
          isResponsive
        >
          {primaryActionText}
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};

MediumModal.HeaderText = Modal.HeaderText;
MediumModal.BoldText = Modal.BoldText;
MediumModal.Text = Modal.Text;
MediumModal.Button = Modal.Button;
MediumModal.ButtonText = Modal.ButtonText;
MediumModal.Block = Modal.Block;
MediumModal.BlockHeader = Modal.BlockHeader;

// --------------------------------------------------
// Props
// --------------------------------------------------
MediumModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isScrollable: PropTypes.bool,
  style: PropTypes.object,
  HeaderComponent: PropTypes.func,
  primaryActionColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

MediumModal.defaultProps = {
  title: '',
  description: '',
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  secondaryActionText: '',
  isSubmitting: false,
  isDisabled: false,
  isScrollable: false,
  style: {},
  HeaderComponent: null,
  primaryActionColor: colors.blue.interactive,
};

export default MediumModal;
