// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {Device} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const ReloadComponent = () => (
  <EmptyState.DescriptionText style={{textAlign: 'center'}}>
    {`Check your connection and `}
    <LinkButton onPress={() => Device.restartApp()}>
      <LinkText>reload</LinkText>
    </LinkButton>
    {` the page.`}
  </EmptyState.DescriptionText>
);

const DefaultMessageComponent = () => (
  <EmptyState.DescriptionText style={{textAlign: 'center'}}>
    {`There was an error retrieving data. `}
    <ReloadComponent />
  </EmptyState.DescriptionText>
);

const ErrorState = ({
  title,
  message,
  MessageComponent,
}: {
  title?: string;
  message?: string;
  MessageComponent?: React.FC;
}) => {
  return (
    <EmptyState
      icon={Icon.ExclamationTriangle}
      iconColor={colors.red.warning}
      title={title}
      message={message}
      MessageComponent={message ? null : MessageComponent}
    />
  );
};

const ErrorStateCentered = ({
  title,
  message,
  MessageComponent,
}: {
  title?: string;
  message?: string;
  MessageComponent?: React.FC;
}) => {
  return (
    <EmptyState.ContentContainer>
      <ErrorState title={title} message={message} MessageComponent={MessageComponent} />
    </EmptyState.ContentContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ErrorState.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  MessageComponent: PropTypes.func,
};

ErrorState.defaultProps = {
  title: 'An error has occurred.',
  message: '',
  MessageComponent: DefaultMessageComponent,
};

ErrorState.DescriptionText = EmptyState.DescriptionText;
ErrorState.Reload = ReloadComponent;
ErrorState.Centered = ErrorStateCentered;

export default ErrorState;
