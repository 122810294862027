// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled, SupermoveLogo} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Column = Styled.View`
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const SmallTitleText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const HelpText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

const ContentContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const HelpVideoLink = ({url}: {url: string}) => {
  const responsive = useResponsive();
  return (
    <a target={'_blank'} href={url}>
      <LinkText responsive={responsive}>Watch this video</LinkText>
    </a>
  );
};

const HelpCenterLink = () => {
  const responsive = useResponsive();
  return (
    <a target={'_blank'} href={'https://supermovehelp.zendesk.com/hc/en-us'}>
      <LinkText responsive={responsive}>Help Center</LinkText>
    </a>
  );
};

const EmptyState = ({
  showLogo,
  icon,
  iconColor,
  title,
  message,
  MessageComponent,
  primaryActionIcon,
  primaryActionIconRight,
  primaryActionText,
  handlePrimaryAction,
  secondaryActionIcon,
  secondaryActionText,
  handleSecondaryAction,
  helpVideoUrl,
  paddingVertical,
  isSmallTitle,
  style,
}: {
  showLogo?: boolean;
  icon?: IconSource;
  iconColor?: string;
  title?: string;
  message?: string;
  MessageComponent?: React.FC | null;
  primaryActionIcon?: IconSource;
  primaryActionIconRight?: IconSource;
  primaryActionText?: string;
  handlePrimaryAction?: () => void;
  secondaryActionIcon?: IconSource;
  secondaryActionText?: string;
  handleSecondaryAction?: () => void;
  helpVideoUrl?: string;
  paddingVertical?: number;
  isSmallTitle?: boolean;
  style?: object;
}) => {
  const responsive = useResponsive();
  return (
    <Column style={style}>
      {paddingVertical && <Space height={paddingVertical} />}
      {showLogo && (
        <React.Fragment>
          <SupermoveLogo size={110} color={colors.gray.border} />
          <Space height={44} />
        </React.Fragment>
      )}
      {icon && (
        <React.Fragment>
          <Icon source={icon} size={40} color={iconColor} />
          <Space height={16} />
        </React.Fragment>
      )}
      {!!title && (
        <React.Fragment>
          {isSmallTitle ? (
            <SmallTitleText responsive={responsive}>{title}</SmallTitleText>
          ) : (
            <TitleText responsive={responsive}>{title}</TitleText>
          )}
          <Space height={16} />
        </React.Fragment>
      )}
      {message !== '' && <DescriptionText responsive={responsive}>{message}</DescriptionText>}
      {MessageComponent && <MessageComponent />}
      {(handlePrimaryAction || handleSecondaryAction) && (
        <React.Fragment>
          <Space height={16} />
          <Row>
            {handlePrimaryAction && (
              <Button
                iconLeft={primaryActionIcon}
                iconRight={primaryActionIconRight}
                text={primaryActionText}
                onPress={handlePrimaryAction}
                isResponsive
              />
            )}
            {handlePrimaryAction && handleSecondaryAction && <Space width={16} />}
            {handleSecondaryAction && (
              <SecondaryButton
                iconLeft={secondaryActionIcon}
                text={secondaryActionText}
                onPress={handleSecondaryAction}
                isResponsive
              />
            )}
          </Row>
        </React.Fragment>
      )}
      {helpVideoUrl && (
        <React.Fragment>
          <Space height={40} />
          <Row>
            <HelpText responsive={responsive}>
              {`Not sure about how it works? `}
              <HelpVideoLink url={helpVideoUrl} />
              {` or visit our `}
              <HelpCenterLink />
              {`.`}
            </HelpText>
          </Row>
        </React.Fragment>
      )}
      {paddingVertical && <Space height={paddingVertical} />}
    </Column>
  );
};

EmptyState.ContentContainer = ContentContainer;
EmptyState.DescriptionText = DescriptionText;
EmptyState.LinkText = LinkText;

// --------------------------------------------------
// Props
// --------------------------------------------------
EmptyState.propTypes = {
  showLogo: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  MessageComponent: PropTypes.func,
  primaryActionIcon: PropTypes.string,
  primaryActionIconRight: PropTypes.string,
  primaryActionText: PropTypes.string,
  handlePrimaryAction: PropTypes.func,
  secondaryActionIcon: PropTypes.string,
  secondaryActionText: PropTypes.string,
  handleSecondaryAction: PropTypes.func,
  helpVideoUrl: PropTypes.string,
  paddingVertical: PropTypes.number,
  isSmallTitle: PropTypes.bool,
  style: PropTypes.object,
};

EmptyState.defaultProps = {
  showLogo: false,
  icon: null,
  iconColor: colors.gray.secondary,
  message: '',
  MessageComponent: null,
  primaryActionIcon: null,
  primaryActionIconRight: null,
  primaryActionText: null,
  handlePrimaryAction: null,
  secondaryActionIcon: null,
  secondaryActionText: null,
  handleSecondaryAction: null,
  helpVideoUrl: null,
  paddingVertical: null,
  isSmallTitle: false,
  style: null,
};

export default EmptyState;
