// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Button = Styled.LoadingButton`
  height: 30px;
  width: 30px;
`;

const IconButtonDelete = ({onPress}: {onPress: () => void}) => {
  return (
    <Button color={colors.Pink600} onPress={onPress}>
      <Icon color={colors.white} size={14} source={Icon.Trash} />
    </Button>
  );
};

export default IconButtonDelete;
