import {useCallback, useEffect, useState} from '@supermove/hooks';

import {
  SSEEvent,
  SSEChannelSubscriptionRequest,
  SSEEventTypes,
} from '@shared/modules/SSE/interfaces';
import {SSEManager} from '@shared/modules/SSE/v2/manager/SSEManager';

/**
 * A hook that subscribes to SSE events from the singleton manager.
 * Components will only re-render when relevant events are received.
 *
 * @param eventTypes - Array of event types to subscribe to
 * @param channelFilter - Optional channel filter to limit events to a specific channel
 * @returns Object with events, latestEvent, and connection status
 */
export const useSSEv2 = (
  eventTypes: SSEEventTypes[],
  initialChannelFilters: SSEChannelSubscriptionRequest[],
) => {
  const [channelFilters, setChannelFilters] =
    useState<SSEChannelSubscriptionRequest[]>(initialChannelFilters);
  const [events, setEvents] = useState<SSEEvent[]>([]);
  const [latestEvent, setLatestEvent] = useState<SSEEvent | null>(null);
  const [connected, setConnected] = useState(false);

  // Initialize state from manager on first render
  useEffect(() => {
    const manager = SSEManager.getInstance();
    setConnected(manager.isConnected());

    // Set initial events from manager if any exist
    const initialEvents = manager.getFilteredEvents(eventTypes, channelFilters);
    if (initialEvents.length > 0) {
      setEvents(initialEvents);
      setLatestEvent(initialEvents[initialEvents.length - 1]);
    }
  }, []);

  // Subscribe to connection status changes
  useEffect(() => {
    const manager = SSEManager.getInstance();
    let intervalId: number | null = null;

    // Check connection status periodically
    intervalId = window.setInterval(() => {
      const isConnected = manager.isConnected();
      setConnected(isConnected);
    }, 2000);

    return () => {
      if (intervalId !== null) {
        window.clearInterval(intervalId);
      }
    };
  }, []);

  // Subscribe to events
  useEffect(() => {
    const manager = SSEManager.getInstance();

    // Define our event handler
    const handleEvent = (event: SSEEvent) => {
      // Update events (keeping uniqueness and max count)
      setEvents((prev) => {
        const combined = [...prev, event];
        // Filter out duplicates by eventId
        const unique = combined.reduce<SSEEvent[]>((acc, curr) => {
          if (!acc.some((e) => e.eventId === curr.eventId)) {
            acc.push(curr);
          }
          return acc;
        }, []);
        // Keep only the latest 100 events
        return unique.slice(-100);
      });

      // Update latest event
      setLatestEvent(event);
    };

    // Subscribe to events
    const unsubscribe = manager.subscribe(eventTypes, handleEvent, channelFilters);

    // Cleanup on unmount
    return () => {
      unsubscribe();
    };
  }, [eventTypes, channelFilters]);

  /**
   * Clears events from the component state
   */
  const flushEvents = useCallback(() => {
    setEvents([]);
    setLatestEvent(null);
  }, []);

  return {
    setChannelFilters,
    events,
    latestEvent,
    connected,
    flushEvents,
  };
};
