// Supermove
import {useEffect} from '@supermove/hooks';

// App
import {getManagerSSEChannels} from '@shared/modules/SSE/components/SSEConfig';
import {SSEManager} from '@shared/modules/SSE/v2/manager/SSEManager';
/**
 * A hook that initializes the SSE singleton manager.
 * This should be called once at the application root level.
 */
export const useInitSSEManager = (userId: string, organizationId: string) => {
  useEffect(() => {
    if (!userId || !organizationId) {
      console.warn('[SSE]: Missing userId or organizationId, not initializing SSE manager.');
      return;
    }

    const manager = SSEManager.getInstance();
    manager.init(userId, organizationId, getManagerSSEChannels({viewerId: userId, organizationId}));

    // Cleanup on app unmount
    return () => {
      manager.destroy();
    };
  }, [userId, organizationId]);
};
