// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Sheet from '@shared/design/components/Sheet';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const FullPageSheet = ({
  isOpen,
  handleClose,
  headerText,
  children,
}: {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  children: React.ReactNode;
}) => {
  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={false}
      headerText={headerText}
      style={{
        flex: 1,
        maxHeight: '100%',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      headerContainerStyle={{backgroundColor: colors.white, paddingTop: 12, paddingBottom: 12}}
    >
      <Line />
      {children}
    </Sheet>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FullPageSheet.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  headerText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FullPageSheet.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  headerText: '',
};

export default FullPageSheet;
