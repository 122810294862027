// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.ButtonV2<{
  color: string;
  width: string;
}>`
  width: ${({width}) => width};
  border-width: 1px;
  border-color: ${({color}) => color};
  border-radius: 4px;
  background-color: ${({color}) => colors.getBackgroundColor(color)};
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const StatusText = Styled.Text<{
  color: string;
}>`
  ${Typography.Responsive.Micro}
  color: ${({color}) => color};
`;

const DetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const StatusCard = ({
  color = colors.green.status,
  onPress = null,
  icon = null,
  statusText = '',
  headerText = '',
  detailsText = '',
  width = '100%',
  responsive,
  style,
}: {
  color?: string;
  onPress?: null | (() => void);
  icon?: IconSource | null;
  statusText?: string;
  headerText?: string;
  detailsText?: string;
  width?: string;
  responsive?: ResponsiveType;
  style?: object;
}) => {
  const isMobile = responsive && !responsive.desktop;
  return (
    <Container onPress={onPress} disabled={!onPress} color={color} width={width} style={style}>
      <Row style={{justifyContent: 'space-between'}}>
        <Row style={{flex: 1}}>
          {icon && (
            <React.Fragment>
              <Icon
                source={icon}
                color={colors.gray.secondary}
                size={Icon.Sizes.Medium + (isMobile ? 2 : 0)}
              />
              <Space width={4} />
            </React.Fragment>
          )}
          <HeaderText numberOfLines={1} responsive={responsive}>
            {headerText}
          </HeaderText>
        </Row>
        <StatusText numberOfLines={1} color={color} responsive={responsive}>
          {statusText}
        </StatusText>
      </Row>
      <Space height={8} />
      <DetailsText responsive={responsive}>{detailsText}</DetailsText>
    </Container>
  );
};

export default StatusCard;
