// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {ProjectModel, UserFlow, UserFlowModel, UserModel} from '@supermove/models';
import {withFragment, uuid} from '@supermove/utils';

// App
import EmailForm, {EmailFormType} from '@shared/modules/Email/forms/EmailForm';
import {UserFlowExpirationUnitType} from '@shared/modules/UserFlows/enums/UserFlowExpirationUnitType';
import UserFlowRunStepForm, {
  UserFlowRunStepFormType,
} from '@shared/modules/UserFlows/forms/UserFlowRunStepForm';

export type UserFlowRunFormType = {
  userFlowRunUuid: string;
  userFlowId: string;
  projectId: string;
  expirationUnitType?: UserFlowExpirationUnitType;
  expirationValue?: string;
  userFlowRunStepForms: UserFlowRunStepFormType[];
  emailForm: EmailFormType;
};

const _new = withFragment(
  ({
    viewer,
    project,
    userFlow,
  }: {
    viewer: UserModel;
    project: ProjectModel;
    userFlow: UserFlowModel;
  }): UserFlowRunFormType => ({
    userFlowRunUuid: uuid(),
    userFlowId: userFlow.id,
    projectId: project.id,
    expirationUnitType: userFlow.expirationUnitType,
    expirationValue: _.toString(userFlow.expirationValue),
    emailForm: EmailForm.newFromProjectWithEmailTemplate({
      viewer,
      project,
      emailTemplate: userFlow.defaultEmailTemplate,
    }),
    userFlowRunStepForms: userFlow.userFlowSteps
      .filter((userFlowStep) => userFlowStep.isDefault)
      .map(UserFlowRunStepForm.new),
  }),
  gql`
    ${EmailForm.newFromProjectWithEmailTemplate.fragment}
    ${UserFlow.getUserFlowWithUserFlowSteps.fragment}

    fragment UserFlowRunForm_new_Project on Project {
      id
      ...EmailForm_newFromProjectWithEmailTemplate_Project
    }

    fragment UserFlowRunForm_new_UserFlow on UserFlow {
      id
      defaultEmailTemplate {
        id
        kind
        ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
      }
      ...UserFlow_getUserFlowWithUserFlowRunSteps
    }

    fragment UserFlowRunForm_new_User on User {
      id
      ...EmailForm_newFromProjectWithEmailTemplate_User
    }
  `,
);

const toForm = (userFlowRun: UserFlowRunFormType): UserFlowRunFormType => {
  return {
    userFlowRunUuid: userFlowRun.userFlowRunUuid,
    userFlowId: userFlowRun.userFlowId,
    projectId: userFlowRun.projectId,
    expirationUnitType: userFlowRun.expirationUnitType,
    expirationValue: userFlowRun.expirationValue,
    userFlowRunStepForms: userFlowRun.userFlowRunStepForms,
    emailForm: userFlowRun.emailForm,
  };
};

const toMutation = (userFlowRunForm: UserFlowRunFormType) => {
  return {
    userFlowRunUuid: userFlowRunForm.userFlowRunUuid,
    userFlowId: userFlowRunForm.userFlowId,
    projectId: userFlowRunForm.projectId,
    expirationUnitType: userFlowRunForm.expirationUnitType,
    expirationValue: _.toNumber(userFlowRunForm.expirationValue),
    userFlowRunStepForms: userFlowRunForm.userFlowRunStepForms,
    emailForm: EmailForm.toMutation(userFlowRunForm.emailForm),
  };
};

const UserFlowRunForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UserFlowRunForm;
