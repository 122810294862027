// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {useResponsive, useToast} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// Shared
import Toast from '@shared/design/components/Toast';

const Text = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const ToastButton = Styled.ButtonV2`
`;

export type Placement = 'top' | 'bottom' | 'right' | 'left';

const TooltipOverlay = ({
  text,
  placement,
  numberOfLines,
  isVisible,
  style,
  children,
}: {
  text: string;
  placement?: Placement;
  numberOfLines?: number;
  isVisible?: boolean;
  style?: object;
  children: React.ReactNode;
}) => {
  return (
    <Tooltip
      overlay={
        <Text numberOfLines={numberOfLines} style={style}>
          {text}
        </Text>
      }
      placement={placement}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      overlayStyle={style || {maxWidth: '20%'}}
      children={children}
      {...(_.isNil(isVisible) ? {} : {visible: isVisible})}
    />
  );
};

const ToastTip = ({
  text,
  style,
  children,
}: {
  text: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const toastTip = useToast({
    ToastComponent: Toast,
    message: text,
  });

  return (
    <ToastButton onPress={toastTip.handleToast} style={style}>
      {children}
    </ToastButton>
  );
};

export type TextTooltipTextType = string | null | undefined;

export type TextTooltipProps = {
  text?: TextTooltipTextType;
  placement?: Placement;
  numberOfLines?: number;
  isEnabledMobileToast?: boolean;
  isVisible?: boolean;
  toastButtonStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const TextTooltip = ({
  text,
  placement,
  numberOfLines,

  // This flag should remain enabled. It is here for backwards compatibility and
  // has been disabled for pre-existing components to avoid unexpected changes.
  isEnabledMobileToast = true,
  isVisible,

  toastButtonStyle,
  style,
  children,
}: TextTooltipProps) => {
  const responsive = useResponsive();

  if (text) {
    if (responsive.desktop) {
      return (
        <TooltipOverlay
          text={text}
          placement={placement}
          numberOfLines={numberOfLines}
          isVisible={isVisible}
          style={style}
        >
          {children}
        </TooltipOverlay>
      );
    } else if (isEnabledMobileToast) {
      return (
        <ToastTip text={text || ''} style={toastButtonStyle}>
          {children}
        </ToastTip>
      );
    }
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default TextTooltip;
