// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Popover} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import DropdownSheet, {
  DropdownActionType,
} from '@shared/design/components/DropdownInput/components/DropdownSheet';

interface ActionMenuPopoverProps {
  popover: {
    key: string;
    isOpen: boolean;
    ref: React.RefObject<HTMLSpanElement> | undefined;
    handleOpen: () => void;
    handleClose: () => void;
  };
  placement?: string;
  width?: number;
  popoverHeight?: number;
  popoverMaxHeight?: number;
  actions: DropdownActionType[];
  sheetLabel?: string;
  isSearchable?: boolean;
  NoResultsComponent?: React.FC | null;
  noResultsComponentProps?: object;
  shouldMobileSheetCloseOnClickOutside?: boolean;
  children: React.ReactNode;
}

const ActionMenuPopover = ({
  popover,
  placement,
  width,
  popoverHeight,
  popoverMaxHeight,
  actions,
  sheetLabel,
  isSearchable,
  NoResultsComponent,
  noResultsComponentProps,
  shouldMobileSheetCloseOnClickOutside,
  children,
}: ActionMenuPopoverProps) => {
  const responsive = useResponsive();
  const isDropdownSheet = !responsive.desktop;

  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>{children}</Popover.Content>
      <Popover
        key={popover.key}
        placement={placement}
        isOpen={!isDropdownSheet && popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={popover.handleClose}
          width={width}
          height={popoverHeight}
          maxHeight={popoverMaxHeight}
          actions={actions}
          isSearchable={isSearchable}
          isResponsive
        />
      </Popover>
      {isDropdownSheet && (
        <DropdownSheet
          isOpen={isDropdownSheet && popover.isOpen}
          handleClose={popover.handleClose}
          headerText={sheetLabel}
          isSearchable={isSearchable}
          options={DropdownSheet.formatActionsForDropdownSheet({
            actions: actions || [],
            handleClose: popover.handleClose,
          })}
          NoResultsComponent={NoResultsComponent}
          noResultsComponentProps={noResultsComponentProps}
          shouldCloseOnClickOutside={shouldMobileSheetCloseOnClickOutside}
        />
      )}
    </React.Fragment>
  );
};

ActionMenuPopover.Position = Popover.Positions;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionMenuPopover.propTypes = {
  popover: PropTypes.object.isRequired,
  placement: PropTypes.string,
  width: PropTypes.number,
  popoverMaxHeight: PropTypes.number,
  actions: PropTypes.array,
  sheetLabel: PropTypes.string,
  isSearchable: PropTypes.bool,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
  children: PropTypes.node,
};

ActionMenuPopover.defaultProps = {
  placement: Popover.Positions.BottomStart,
  width: null,
  popoverMaxHeight: null,
  actions: [],
  sheetLabel: 'Actions',
  isSearchable: false,
  NoResultsComponent: null,
  noResultsComponentProps: {},
};

export default ActionMenuPopover;
