// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const SegmentedControlsContainer = Styled.View`
  flex-direction: row;
  height: 36px;
`;

const SegmentButton = Styled.ButtonV2<{
  isSelected: boolean;
  isDisabled: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: ${({isFirst}) => (isFirst ? 1 : 0)}px;
  border-right-width: ${({isLast}) => (isLast ? 1 : 0)}px;
  border-top-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-bottom-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-top-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  border-bottom-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.secondary)};
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.gray.background)};
  padding-horizontal: 12px;
`;

// white-space: normal overrides extra padding added to the labels
const SegmentLabel = Styled.Text<{
  vars: {
    isSelected: boolean;
  };
}>`
  ${Typography.Label}
  color: ${({vars}) => (vars.isSelected ? colors.blue.interactive : colors.gray.secondary)};
  white-space: normal;
`;

const SegmentDividerLine = Styled.View<{
  isSelected: boolean;
}>`
  width: 1px;
  background-color: ${({isSelected}) =>
    isSelected ? colors.blue.interactive : colors.gray.secondary};
`;

const SegmentDivider = ({
  isSelected,
  index,
  segmentDefinitions,
}: {
  isSelected: boolean;
  index: number;
  segmentDefinitions: SegmentDefinition[];
}) => {
  const priorSegment = _.get(segmentDefinitions, index - 1);
  return <SegmentDividerLine isSelected={isSelected && priorSegment.isSelected} />;
};

export interface SegmentDefinition {
  label: string;
  count?: string;
  isSelected: boolean;
  isDisabled: boolean;
  onPress: () => void;
}

const SegmentedControls = ({segmentDefinitions}: {segmentDefinitions: SegmentDefinition[]}) => {
  return (
    <SegmentedControlsContainer>
      {segmentDefinitions.map(({label, count, isSelected, isDisabled, onPress}, index) => {
        const isFirst = index === 0;
        const isLast = index === segmentDefinitions.length - 1;
        return (
          <React.Fragment key={label}>
            {!isFirst && (
              <SegmentDivider
                isSelected={isSelected}
                index={index}
                segmentDefinitions={segmentDefinitions}
              />
            )}
            <SegmentButton
              isSelected={isSelected}
              disabled={isDisabled}
              onPress={onPress}
              isFirst={isFirst}
              isLast={isLast}
            >
              <SegmentLabel vars={{isSelected}}> {label}</SegmentLabel>
              {!_.isNil(count) && (
                <React.Fragment>
                  <Space width={8} />
                  <SegmentLabel vars={{isSelected}}>{count}</SegmentLabel>
                </React.Fragment>
              )}
            </SegmentButton>
          </React.Fragment>
        );
      })}
    </SegmentedControlsContainer>
  );
};

export default SegmentedControls;
