// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const Container = Styled.View`
  flex: 1;
`;

const SwitchOuterOval = Styled.View<{
  size: SizeType;
  isOn: boolean;
  color: string;
  activeOpacity?: number;
}>`
  height: ${({size}) => DIMENSIONS[size].OUTER_OVAL_HEIGHT}px;
  width: ${({size}) => DIMENSIONS[size].OUTER_OVAL_WIDTH}px;
  background-color: ${({color}) => color};
  border-radius: 20px;
  border: 1px solid ${({color}) => color};
  justify-content: center;
  align-items: ${(isOn) => (isOn ? 'flex-end' : 'flex-start')};
  padding: 1px;
  margin-top: 2px;
  align-self: flex-start;
`;

const SwitchInnerCircle = Styled.View<{size: SizeType; color: string}>`
  height: ${({size}) => DIMENSIONS[size].INNER_CIRCLE_SIZE}px;
  width: ${({size}) => DIMENSIONS[size].INNER_CIRCLE_SIZE}px;
  border-radius: ${({size}) => DIMENSIONS[size].INNER_CIRCLE_SIZE / 2}px;
  background-color: ${({color}) => color};
  align-items: center;
  justify-content: center;
`;

const SwitchOnIndicator = Styled.View<{size: SizeType; color: string}>`
  width: 2px;
  height: ${({size}) => DIMENSIONS[size].ON_INDICATOR_HEIGHT}px;
  border-radius: 2px;
  background-color: ${({color}) => color};
`;

const SwitchText = Styled.Text<{vars: {disabled?: boolean; hasErrors?: boolean}}>`
  ${Typography.Responsive.Body}
  color: ${({vars}) =>
    vars.hasErrors
      ? colors.red.warning
      : vars.disabled
        ? colors.gray.secondary
        : colors.gray.primary}
`;

const HintText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  XLARGE: 'XLARGE',
} as const;

type SizeType = keyof typeof SIZES;

const SIZE_TO_MOBILE_SIZE: Record<SizeType, SizeType> = {
  [SIZES.SMALL]: SIZES.MEDIUM,
  [SIZES.MEDIUM]: SIZES.LARGE,
  [SIZES.LARGE]: SIZES.XLARGE,
  [SIZES.XLARGE]: SIZES.XLARGE,
};

const DIMENSIONS: Record<
  SizeType,
  {
    OUTER_OVAL_WIDTH: number;
    OUTER_OVAL_HEIGHT: number;
    INNER_CIRCLE_SIZE: number;
    ON_INDICATOR_HEIGHT: number;
  }
> = {
  SMALL: {
    OUTER_OVAL_WIDTH: 18,
    OUTER_OVAL_HEIGHT: 12,
    INNER_CIRCLE_SIZE: 8,
    ON_INDICATOR_HEIGHT: 6,
  },
  MEDIUM: {
    OUTER_OVAL_WIDTH: 24,
    OUTER_OVAL_HEIGHT: 16,
    INNER_CIRCLE_SIZE: 12,
    ON_INDICATOR_HEIGHT: 8,
  },
  LARGE: {
    OUTER_OVAL_WIDTH: 30,
    OUTER_OVAL_HEIGHT: 20,
    INNER_CIRCLE_SIZE: 16,
    ON_INDICATOR_HEIGHT: 10,
  },
  XLARGE: {
    OUTER_OVAL_WIDTH: 36,
    OUTER_OVAL_HEIGHT: 24,
    INNER_CIRCLE_SIZE: 20,
    ON_INDICATOR_HEIGHT: 12,
  },
};

const getSwitchColor = ({
  disabled,
  hasErrors,
  isOn,
  color,
}: {
  disabled: boolean;
  hasErrors?: boolean;
  isOn: boolean;
  color: string;
}) => {
  if (disabled) {
    return colors.gray.disabled;
  }
  if (hasErrors) {
    return colors.red.warning;
  }
  if (isOn) {
    return color;
  }
  return colors.gray.tertiary;
};

const SwitchContainer = ({
  onPress,
  disabled,
  isHitSlop,
  hitSlop,
  children,
}: {
  onPress: () => void;
  disabled: boolean;
  isHitSlop: boolean;
  hitSlop: object;
  children: React.ReactNode;
}) => {
  return (
    <TertiaryButton isHitSlop={isHitSlop} hitSlop={hitSlop} onPress={onPress} isDisabled={disabled}>
      {children}
    </TertiaryButton>
  );
};

const SwitchButton = ({
  isOn,
  color,
  size,
  disabled,
  hasErrors,
  isMobile,
}: {
  isOn: boolean;
  color: string;
  size: SizeType;
  disabled: boolean;
  hasErrors?: boolean;
  isMobile?: boolean;
}) => {
  const switchColor = getSwitchColor({disabled, hasErrors, isOn, color});
  const displaySize = isMobile ? SIZE_TO_MOBILE_SIZE[size] : size;
  return (
    <SwitchOuterOval isOn={isOn} color={switchColor} activeOpacity={0.8} size={displaySize}>
      <SwitchInnerCircle size={displaySize} color={colors.white}>
        {isOn && <SwitchOnIndicator size={displaySize} color={switchColor} />}
      </SwitchInnerCircle>
    </SwitchOuterOval>
  );
};

const Switch = ({
  isOn,
  color,
  onChange,
  labelLeft,
  labelRight,
  size,

  // TODO(dan) Remove all usages of the isMobile prop
  isMobile,

  isResponsive,
  disabled,
  hasErrors,
  hint,
  isHitSlop,
  hitSlop,
}: {
  isOn: boolean;
  color: string;
  size: SizeType;
  disabled: boolean;
  onChange: (isOn: boolean) => void;
  isHitSlop: boolean;
  hitSlop: object;
  isMobile?: boolean;
  isResponsive?: boolean;
  labelLeft?: string;
  labelRight?: string;
  hint?: string;
  hasErrors?: boolean;
}) => {
  const responsive = useResponsive();

  return (
    <SwitchContainer
      onPress={() => !disabled && onChange(!isOn)}
      disabled={disabled}
      isHitSlop={isHitSlop}
      hitSlop={hitSlop}
    >
      {!!labelLeft && (
        <React.Fragment>
          <SwitchText
            responsive={isMobile || isResponsive ? responsive : null}
            vars={{disabled, hasErrors}}
          >
            {labelLeft}
          </SwitchText>
          <Space width={8} />
        </React.Fragment>
      )}
      <SwitchButton
        isOn={isOn}
        color={color}
        size={size}
        disabled={disabled}
        hasErrors={hasErrors}
        isMobile={isMobile || (isResponsive && !responsive.desktop)}
      />
      {!!labelRight && (
        <React.Fragment>
          <Space width={8} />
          <Container>
            <SwitchText
              responsive={isMobile || isResponsive ? responsive : null}
              vars={{disabled, hasErrors}}
            >
              {labelRight}
            </SwitchText>
            {!!hint && (
              <React.Fragment>
                <Space height={4} />
                <HintText responsive={isMobile || isResponsive ? responsive : null}>
                  {hint}
                </HintText>
              </React.Fragment>
            )}
          </Container>
        </React.Fragment>
      )}
    </SwitchContainer>
  );
};

Switch.Container = SwitchContainer;
Switch.Button = SwitchButton;
Switch.Text = SwitchText;
Switch.SIZE = SIZES;
Switch.DIMENSIONS = DIMENSIONS;

// --------------------------------------------------
// Props
// --------------------------------------------------
Switch.propTypes = {
  isOn: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isHitSlop: PropTypes.bool,
  hitSlop: PropTypes.object,
  isMobile: PropTypes.bool,
  isResponsive: PropTypes.bool,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  hint: PropTypes.string,
};

Switch.defaultProps = {
  isOn: undefined,
  disabled: false,
  onChange: () => {},
  isMobile: undefined,
  isResponsive: undefined,
  labelLeft: undefined,
  labelRight: undefined,
  hint: undefined,
  color: colors.blue.interactive,
  size: SIZES.MEDIUM,
  isHitSlop: true,
  hitSlop: {top: 4, bottom: 4, left: 8, right: 8},
};

export default Switch;
