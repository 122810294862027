// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Toast from '@shared/design/components/Toast';

const ErrorToast = ({
  message,
  actionText = '',
  handleAction = () => {},
  handleDismiss = () => {},
  handleRemove = () => {},
}: {
  message: string;
  actionText?: string | null;
  handleAction?: () => void;
  handleDismiss?: () => void;
  handleRemove?: () => void;
}) => {
  return (
    <Toast
      icon={Icon.Ban}
      message={message}
      actionText={actionText}
      handleAction={handleAction}
      handleDismiss={handleDismiss}
      handleRemove={handleRemove}
      color={colors.red.warning}
    />
  );
};

export default ErrorToast;
