// Libraries
import {isEqual, debounce} from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

// App
import {getSSEUrl} from '@shared/modules/SSE/components/SSEConfig';
import {
  SSEEvent,
  SSEChannelSubscriptionRequest,
  SSEEventTypes,
} from '@shared/modules/SSE/interfaces';

type EventCallback = (event: SSEEvent) => void;

interface EventSubscription {
  eventTypes: SSEEventTypes[];
  callback: EventCallback;
  channelFilters: SSEChannelSubscriptionRequest[];
}

/**
 * SSEManager is a singleton class that provides a central management system for
 * SSE (Server-Sent Events) connections. It allows components to subscribe to specific
 * event types and channels without causing unnecessary re-renders when other events arrive.
 */
export class SSEManager {
  private static instance: SSEManager | null = null;

  private eventSource: EventSource | null = null;

  private connected = false;

  private connectedWithChannels: SSEChannelSubscriptionRequest[] = [];

  private userId: string | null = null;

  private organizationId: string | null = null;

  private defaultChannels: SSEChannelSubscriptionRequest[] = [];

  private subscribers: Map<string, EventSubscription[]> = new Map();

  private connectionAttempt = 0;

  private events: SSEEvent[] = [];

  private constructor() {
    // Private constructor to enforce singleton pattern
  }

  /**
   * Gets the singleton instance of SSEManager
   */
  public static getInstance(): SSEManager {
    if (!SSEManager.instance) {
      SSEManager.instance = new SSEManager();
    }
    return SSEManager.instance;
  }

  /**
   * Initializes the SSE connection with user and organization details
   */
  public init(
    userId: string,
    organizationId: string,
    defaultChannels: SSEChannelSubscriptionRequest[],
  ) {
    this.userId = userId;
    this.organizationId = organizationId;
    console.log('[SSE]: Initialized with userId:', userId, 'and organizationId:', organizationId);

    // If we have initial channels, connect immediately
    if (defaultChannels.length > 0) {
      this.defaultChannels = defaultChannels;
      this.connect();
    }

    // Handle tab visibility changes
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
  }

  /**
   * Handles tab visibility changes to close/reopen connection
   */
  private handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.closeConnection();
      console.log('[SSE]: Connection closed due to tab becoming hidden.');
    } else if (document.visibilityState === 'visible') {
      this.connect();
      console.log('[SSE]: Connection reopened due to tab becoming visible.');
    }
  };

  /**
   * Subscribes to specific event types with an optional channel filter
   */
  public subscribe(
    eventTypes: SSEEventTypes[],
    callback: EventCallback,
    channelFilters: SSEChannelSubscriptionRequest[],
  ): () => void {
    const subscriberId = uuid();
    const definedChannelFilters = channelFilters.filter((filter) => filter.channelId !== undefined);

    const subscription: EventSubscription = {
      eventTypes,
      callback,
      channelFilters: definedChannelFilters,
    };

    if (this.subscribers.has(subscriberId)) {
      this.subscribers.get(subscriberId)!.push(subscription);
    } else {
      this.subscribers.set(subscriberId, [subscription]);
    }
    this.reconnectIfChannelsChanged();

    // Return unsubscribe function
    return () => {
      if (this.subscribers.has(subscriberId)) {
        const subs = this.subscribers.get(subscriberId)!;
        const index = subs.findIndex(
          (sub) =>
            sub.callback === callback &&
            isEqual(sub.eventTypes, eventTypes) &&
            ((!sub.channelFilters && !definedChannelFilters) ||
              (sub.channelFilters &&
                definedChannelFilters &&
                sub.channelFilters.every((filter) =>
                  definedChannelFilters.some(
                    (cFilter) =>
                      cFilter.channelType === filter.channelType &&
                      cFilter.channelId === filter.channelId,
                  ),
                ))),
        );

        if (index !== -1) {
          subs.splice(index, 1);
          if (subs.length === 0) {
            this.subscribers.delete(subscriberId);
          }
          this.reconnectIfChannelsChanged();
        }
      }
    };
  }

  /**
   * Gets all stored events
   */
  public getEvents(): SSEEvent[] {
    return [...this.events];
  }

  /**
   * Gets events filtered by event types and optional channel
   */
  public getFilteredEvents(
    eventTypes: SSEEventTypes[],
    channelFilters: SSEChannelSubscriptionRequest[],
  ): SSEEvent[] {
    return this.events.filter((event) => {
      const typeMatch = eventTypes.length === 0 || eventTypes.includes(event.eventType);

      if (!typeMatch) return false;

      if (channelFilters.length > 0) {
        return channelFilters.some(
          (filter) =>
            event.channelType === filter.channelType && event.channelId === filter.channelId,
        );
      }

      return true;
    });
  }

  /**
   * Gets the most recent event of a specific type and optional channel
   */
  public getLatestEvent(
    eventTypes: SSEEventTypes[],
    channelFilters: SSEChannelSubscriptionRequest[],
  ): SSEEvent | null {
    const filteredEvents = this.getFilteredEvents(eventTypes, channelFilters);
    return filteredEvents.length > 0 ? filteredEvents[filteredEvents.length - 1] : null;
  }

  /**
   * Closes the SSE connection
   */
  public closeConnection() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
      this.connected = false;
    }
  }

  /**
   * Flushes all stored events
   */
  public flushEvents() {
    this.events = [];
  }

  /**
   * Establishes the SSE connection
   */
  private connect() {
    if (!this.userId || !this.organizationId) {
      console.log('[SSE]: Missing userId or organizationId, not opening connection.');
      return;
    }

    if (this.eventSource) {
      console.log('[SSE]: Connection already exists, closing before reconnecting.');
      this.closeConnection();
    }

    const channels = this.extractChannelFilters();
    if (channels.length === 0) {
      console.log('[SSE]: No channels to subscribe to, not opening connection.');
      return;
    }

    try {
      console.log('[SSE]: Opening connection with channels:', channels);
      this.connectedWithChannels = channels;
      this.eventSource = new EventSource(
        getSSEUrl({
          userId: parseInt(this.userId, 10),
          organizationId: parseInt(this.organizationId, 10),
          channels,
        }),
      );

      this.setupEventHandlers();
    } catch (error) {
      console.error('[SSE]: Failed to create EventSource:', error);
      this.handleConnectionError();
    }
  }

  /**
   * Extract all unique channel filters from all subscribers
   */
  private extractChannelFilters(): SSEChannelSubscriptionRequest[] {
    const channels: SSEChannelSubscriptionRequest[] = [...this.defaultChannels];

    this.subscribers.forEach((subscriptions) => {
      subscriptions.forEach((subscription) => {
        if (subscription.channelFilters && subscription.channelFilters.length > 0) {
          subscription.channelFilters.forEach((filter) => {
            if (!this.isChannelFilterExists(channels, filter)) {
              channels.push(filter);
            }
          });
        }
      });
    });

    return channels;
  }

  /**
   * Sets up event handlers for the SSE connection
   */
  private setupEventHandlers() {
    if (!this.eventSource) return;

    this.eventSource.onopen = () => {
      console.log('[SSE]: Connection established.');
      this.connected = true;
      this.connectionAttempt = 0;
    };

    this.eventSource.onerror = (error) => {
      console.error('[SSE]: Error:', error);
      this.handleConnectionError();
    };

    this.eventSource.onmessage = (messageEvent) => {
      try {
        const parsedEvent: SSEEvent = JSON.parse(messageEvent.data);
        if (messageEvent.lastEventId) {
          parsedEvent.eventId = messageEvent.lastEventId;
        }

        // Store the event
        this.events = [...this.events, parsedEvent].slice(-100); // Keep last 100 events

        // Notify subscribers
        this.notifySubscribers(parsedEvent);
      } catch (error) {
        console.error('[SSE]: Error parsing message:', error);
      }
    };
  }

  /**
   * Handles connection errors with exponential backoff
   */
  private handleConnectionError() {
    this.connected = false;
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    const maxAttempts = 10;
    const reconnectAttemptInterval = 5000;
    const maxJitter = 5000;

    if (this.connectionAttempt >= maxAttempts) {
      console.warn('[SSE]: Maximum reconnection attempts reached. Not reconnecting.');
      return;
    }

    const jitter = Math.random() * maxJitter;
    const reconnectDelay = reconnectAttemptInterval + jitter;

    console.log(
      `[SSE]: Attempting to reconnect in ${Math.round(reconnectDelay / 1000)}s (attempt ${this.connectionAttempt + 1}/${maxAttempts})`,
    );

    setTimeout(() => {
      this.connectionAttempt += 1;
      this.connect();
    }, reconnectDelay);
  }

  /**
   * Notifies subscribers of a new event
   */
  private notifySubscribers(event: SSEEvent) {
    const subscribersArray = Array.from(this.subscribers.entries());
    for (const [key, subscriptions] of subscribersArray) {
      for (const subscription of subscriptions) {
        // Check if subscriber cares about this event type
        const typeMatch =
          subscription.eventTypes.length === 0 || subscription.eventTypes.includes(event.eventType);

        // If channel filters exist, check if event matches any of the channels
        const channelMatch =
          subscription.channelFilters.length === 0 ||
          subscription.channelFilters.some(
            (filter) =>
              event.channelType === filter.channelType && event.channelId === filter.channelId,
          );

        if (typeMatch && channelMatch) {
          try {
            subscription.callback(event);
          } catch (error) {
            console.error('[SSE]: Error in subscriber callback:', error);
          }
        }
      }
    }
  }

  private reconnectIfChannelsChanged = debounce(() => {
    const newChannels = this.extractChannelFilters();
    if (
      this.connectedWithChannels.length !== newChannels.length ||
      !this.connectedWithChannels.every((filter) =>
        newChannels.some(
          (cFilter) =>
            cFilter.channelType === filter.channelType && cFilter.channelId === filter.channelId,
        ),
      )
    ) {
      console.log(
        '[SSE]: Channels changed, reconnecting.',
        this.connectedWithChannels,
        newChannels,
      );
      this.connect();
    }
  }, 1000);

  private isChannelFilterExists(
    channelFilters: SSEChannelSubscriptionRequest[],
    filter: SSEChannelSubscriptionRequest,
  ): boolean {
    return channelFilters.some(
      (channelFilter) =>
        channelFilter.channelType === filter.channelType &&
        channelFilter.channelId === filter.channelId,
    );
  }

  /**
   * Checks if the SSE connection is established
   */
  public isConnected(): boolean {
    return this.connected;
  }

  /**
   * Cleans up resources when the manager is no longer needed
   */
  public destroy() {
    this.closeConnection();
    this.subscribers.clear();
    this.events = [];

    if (typeof document !== 'undefined') {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }
  }
}
