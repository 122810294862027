// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled, ToastContainer} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import DropdownSheetOptionsList from '@shared/design/components/DropdownInput/components/DropdownSheetOptionsList';
import DropdownSheetSectionedList from '@shared/design/components/DropdownInput/components/DropdownSheetSectionedList';
import Line from '@shared/design/components/Line';
import SearchBar from '@shared/design/components/SearchBar';
import Sheet from '@shared/design/components/Sheet';

import DropdownSheetOption from './DropdownSheetOption';

const FooterContainer = Styled.View`
  padding: 16px;
`;

export interface ActionType {
  text: string;
  description?: string;
  color?: string;
  isLoadedAction?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: string | null;
  onPress: () => void;
  [key: string]: any; // This allows for the spread operator by supporting additional properties.
}

export interface SectionType {
  label: string;
  actions: ActionType[]; // Nested actions with the same base type.
}

export type DropdownActionType = ActionType | SectionType;

export interface FormatActionsForDropdownSheetParams {
  actions: DropdownActionType[];
  handleClose: () => void;
  isMultiSelect?: boolean;
}

export const isDropdownSection = (action: DropdownActionType): action is SectionType => {
  return 'label' in action;
};

const toActionItem = ({
  text,
  label,
  onPress,
  isLoadedAction,
  handleClose,
  isMultiSelect,
  ...action
}: ActionType & {handleClose: () => void}) => {
  return {
    ...action,
    label: text,
    value: text,
    handleAction: () => {
      onPress();
      if (!isLoadedAction && !isMultiSelect) {
        handleClose();
      }
    },
  };
};

const toDropdownSection = ({
  label,
  actions,
  handleClose,
  isMultiSelect,
  ...section
}: SectionType & {handleClose: () => void; isMultiSelect?: boolean}) => {
  return {
    ...section,
    label,
    options: actions.map((action) => toActionItem({...action, handleClose, isMultiSelect})),
  };
};

const formatActionsForDropdownSheet = ({
  actions,
  handleClose,
  isMultiSelect,
}: FormatActionsForDropdownSheetParams) => {
  return actions.map((action) =>
    isDropdownSection(action)
      ? toDropdownSection({...action, handleClose, isMultiSelect})
      : toActionItem({...action, handleClose, isMultiSelect}),
  );
};

const getOptionMatchesSearch = ({
  option,
  query,
  showDescriptionInOption,
  additionalSearchKeys,
}: {
  option: any;
  query: string;
  showDescriptionInOption: boolean;
  additionalSearchKeys: string[];
}) => {
  // Return all options if query is empty
  if (!query) {
    return true;
  }
  const searchValues = [
    ...List.insertIf(typeof option.label === 'string', option.label),
    ...List.insertIf(!!option.menuLabel, option.menuLabel),
    ...List.insertIf(showDescriptionInOption, option.description),
    ...(additionalSearchKeys ? additionalSearchKeys.map((key) => _.get(option, key)) : []),
  ];
  const searchString = searchValues.join(' ');
  return searchString.toLowerCase().includes(query.toLowerCase());
};

const getFilteredSectionedOptions = ({
  options,
  query,
  showDescriptionInOption,
  additionalSearchKeys,
}: {
  options: any[];
  query: string;
  showDescriptionInOption: boolean;
  additionalSearchKeys: string[];
}) => {
  const sectionsWithFilteredOptions = options.reduce((result: any[], section) => {
    const filteredOptions = section.options.filter(
      (option: any) =>
        !option.isHidden &&
        getOptionMatchesSearch({
          option,
          query,
          showDescriptionInOption,
          additionalSearchKeys,
        }),
    );
    return [...result, {...section, options: filteredOptions}];
  }, []);

  return sectionsWithFilteredOptions.filter((section) => _.some(section.options));
};

const getFilteredOptions = ({
  options,
  query,
  showDescriptionInOption,
  additionalSearchKeys,
}: {
  options: any[];
  query: string;
  showDescriptionInOption: boolean;
  additionalSearchKeys: string[];
}) => {
  const isSectionedList = options[0]?.options;
  if (isSectionedList) {
    return getFilteredSectionedOptions({
      options,
      query,
      showDescriptionInOption,
      additionalSearchKeys,
    });
  }

  return options.filter(
    (option) =>
      !option.isHidden &&
      getOptionMatchesSearch({
        option,
        query,
        showDescriptionInOption,
        additionalSearchKeys,
      }),
  );
};

export interface DropdownSheetProps {
  isOpen: boolean;
  headerText?: string;
  options?: any[];
  name?: string;
  value?: any;
  onChangeValue?: (value: any) => void;
  setFieldValue?: (name: string, value: unknown) => void;
  onInputChange?: (text: string, action: {}) => void;
  isLoading?: boolean;
  isMultiSelect?: boolean;
  isSearchable?: boolean;
  showDescriptionInOption: boolean;
  additionalSearchKeys: string[];
  handleClose: () => void;
  HeaderRightComponent?: React.FC<{
    handleClose: () => void;
    isDisabledClose: boolean;
  }> | null;
  handleSubmit?: () => void;
  handleSubmitText?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  NoResultsComponent?: React.FC | null;
  noResultsComponentProps?: object;
  isFixedHeight?: boolean;
  height?: string;
  isEnabledSelectAll?: boolean;
  isAllSelected?: boolean;
  onSelectAll?: () => void;
  shouldCloseOnClickOutside?: boolean;
}

const DropdownSheet = ({
  isOpen,
  headerText,
  options = [],
  name = '',
  value = '',
  onChangeValue = () => {},
  setFieldValue = () => {},
  onInputChange = () => {},
  isLoading = false,
  isMultiSelect = false,
  isSearchable = true,
  showDescriptionInOption = false,
  additionalSearchKeys = [],
  handleClose,
  HeaderRightComponent = null,
  handleSubmit,
  handleSubmitText,
  isSubmitting = false,
  isDisabled = false,
  errorMessage,
  NoResultsComponent = null,
  noResultsComponentProps = {},
  isFixedHeight = false,
  height = '',
  isEnabledSelectAll,
  isAllSelected,
  onSelectAll,
  shouldCloseOnClickOutside,
}: DropdownSheetProps) => {
  const isSectioned = !!options[0]?.options;
  const [query, setQuery] = useState('');
  const filteredOptions = getFilteredOptions({
    options,
    query,
    showDescriptionInOption,
    additionalSearchKeys,
  });

  useEffect(() => {
    setQuery('');
  }, [isOpen, setQuery]);

  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={headerText}
      isFixedHeight={isSearchable || isFixedHeight}
      height={height}
      HeaderRightComponent={HeaderRightComponent}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
    >
      <ScrollView contentContainerStyle={{paddingHorizontal: 16}}>
        <Space height={8} />
        {isSearchable && (
          <React.Fragment>
            <SearchBar
              isResponsive
              isClearable
              style={{flex: 1}}
              isLoading={isLoading}
              onChangeText={(text) => {
                setQuery(text);
                // {action: 'input-change'} is what our DropdownInput component passes
                // in to the onInputChange to indicate that the search input has changed.
                onInputChange(text, {action: 'input-change'});
              }}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        {isEnabledSelectAll && (
          <React.Fragment>
            <DropdownSheetOption
              option={{label: 'Select All'}}
              isSelected={isAllSelected}
              handleOption={onSelectAll}
            />
            <Line />
          </React.Fragment>
        )}
        {isSectioned ? (
          <DropdownSheetSectionedList
            options={filteredOptions}
            name={name}
            value={value}
            showDescriptionInOption={showDescriptionInOption}
            setFieldValue={setFieldValue}
            onChangeValue={onChangeValue}
            handleClose={handleClose}
            query={query}
            NoResultsComponent={NoResultsComponent}
            noResultsComponentProps={noResultsComponentProps}
          />
        ) : (
          <DropdownSheetOptionsList
            options={filteredOptions}
            name={name}
            value={value}
            showDescriptionInOption={showDescriptionInOption}
            setFieldValue={setFieldValue}
            onChangeValue={onChangeValue}
            handleClose={handleClose}
            query={query}
            NoResultsComponent={NoResultsComponent}
            noResultsComponentProps={noResultsComponentProps}
          />
        )}
        <Space height={16} />
      </ScrollView>
      {errorMessage && (
        <FooterContainer>
          <ErrorCallout text={errorMessage} />
        </FooterContainer>
      )}
      {handleSubmit && (
        <React.Fragment>
          <Line />
          <FooterContainer>
            <Button
              onPress={handleSubmit}
              text={handleSubmitText}
              isSubmitting={isSubmitting}
              isDisabled={isDisabled}
              isResponsive
              isWidthOfContainer
            />
          </FooterContainer>
        </React.Fragment>
      )}
      <ToastContainer />
    </Sheet>
  );
};

DropdownSheet.formatActionsForDropdownSheet = formatActionsForDropdownSheet;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownSheet.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      handleAction: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          handleAction: PropTypes.func,
        }),
      ),
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChangeValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isSearchable: PropTypes.bool,
  showDescriptionInOption: PropTypes.bool,
  additionalSearchKeys: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  HeaderRightComponent: PropTypes.func,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
  isFixedHeight: PropTypes.bool,
  height: PropTypes.string,
};

DropdownSheet.defaultProps = {
  options: [],
  name: '',
  value: '',
  onChangeValue: () => {},
  setFieldValue: () => {},
  onInputChange: () => {},
  isLoading: false,
  isMultiSelect: false,
  isSearchable: true,
  showDescriptionInOption: false,
  additionalSearchKeys: [],
  HeaderRightComponent: null,
  NoResultsComponent: null,
  noResultsComponentProps: {},
  isFixedHeight: false,
  height: null,
};

export default DropdownSheet;
