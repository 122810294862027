// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

const Container = Styled.View`
  width: 100%;
`;

const AnchorText = Styled.Text`
  ${Typography.Link2};
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const AnchorWrapper = Styled.ButtonV2`
`;

const PhoneText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const PhoneNumber = ({
  isClickable = false,
  phoneNumber,
}: {
  isClickable?: boolean;
  phoneNumber: string;
}) => {
  const responsive = useResponsive();
  return (
    <Container>
      {isClickable ? (
        <AnchorWrapper>
          <a href={Phone.createUrl(phoneNumber)}>
            <AnchorText numberOfLines={1}>{Phone.display(phoneNumber)}</AnchorText>
          </a>
        </AnchorWrapper>
      ) : (
        <PhoneText responsive={responsive} numberOfLines={1}>
          {Phone.display(phoneNumber)}
        </PhoneText>
      )}
    </Container>
  );
};

export default PhoneNumber;
